@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

html, body {
    height: 100%;
    margin: 0;
    font-family: poppins !important; 
    background-color: #2e2e2e;
    color: #ffffff;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
    background-color: #2e2e2e;
}

.logo img {
    max-height: 50px;
    width:auto
}

.logo-container {
    display: flex;
    align-items: center;
}

.nav-links a {
    color: #ffffff;
    margin-left: 30px;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 20px;
}

.nav-links a:hover {
    font-weight: bold;
}

.menu-icon {
    display: none;
    cursor: pointer;
}

.filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 2rem;
}

.filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.filter {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 200px;
    max-width: 250px;
    align-items: center;
}

.filter label {
    font-size: 1rem;
    margin-bottom: 20px;
}

.filter input {
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 20px;
    border: 1px solid #555;
    background-color: #222;
    color: white !important;
    width: 100% !important;
    box-sizing: border-box !important;
    text-align: center;
}

.custom-hidden {
    color: #2e2e2e
}

::placeholder {
    color: white !important;
    opacity: 1;
    font-family: poppins;
}

#searchEvent{
    padding: 11px;
}

.react-select__control {
    border-radius: 20px !important;
    border: 1px solid #555 !important;
    background-color: #222 !important;
    color: white !important;
    padding: 2.5px 10px !important;
    font-size: 0.8rem !important;
    width: 20% !important;
    box-sizing: border-box;
    text-align: center !important;
}

.react-select__placeholder {
    color: white !important;
    text-align: center; 
}

.react-select__single-value {
    color: white !important;
}

.react-select__menu {
    background-color: #222 !important;
    color: white !important;
    border-radius: 0 0 20px 20px !important;
    grid-template-columns: 0px !important;
}

.react-select__option {
    background-color: #222 !important;
    color: white !important;
    font-size: 0.8rem !important;
    text-align: center!important;
}

.react-select__option--is-focused {
    background-color: #333 !important;
    color: white !important;
}

.react-select__option--is-selected {
    background-color: #555 !important;
    color: white !important;
}

.floating-filter-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 2000;
}

.floating-filter-button .toggle-filter-menu {
    background-color: #555;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
}

.floating-filter-button .toggle-filter-menu:hover {
    background-color: #777;
}

.filter-close-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.filter-close-button .close-filter-menu {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    padding: 0 20px 0 0;
}

.floating-filter-menu {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(46, 46, 46, 0.95);
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    z-index: 10000000;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    max-height: 70vh;
    flex-direction: column;
    align-items: center;
}

.floating-filter-menu.visible {
    display: flex;
    transform: translateY(0);
}

.floating-filter-menu .filter {
    width: 100%;
    padding: 5px 0;
}

.floating-filter-menu .form-control {
    width: 90%;
    margin: 0 auto;
}
.stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;  
    gap: 10px;
    padding: 20px;
    margin-top: 1rem;
    margin-left: 1rem;
    height: 60vh; 
    overflow: hidden;
}

#map { 
    grid-row: 1 / span 2;
    border-radius: 15px;
    overflow: hidden;
    height: 100% !important;
}

.bar-chart, .pie-chart-minister-selected {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
    background-color: #222;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

}

.pie-chart {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    background-color: #222;
    border-radius: 15px;
}

.event-table {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    overflow-x: auto;
    max-height: 96; 
    padding-bottom: 10px; 
    border-radius: 15px;

}


.custom-marker {
    background-color: #cc0000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

footer {
    text-align: center;
    padding: 10px;
    background-color: #2e2e2e;
    position: relative;
    bottom: 0;
    width: 100%;
}

.custom-h6 {
    font-size: 12px;
    font-family: 'poppins';
    font-weight: bold
}

.minister-info {
    display: flex;
    flex-direction: row; 
    align-items: center;
    background-color: #222;
    border-radius: 15px;
    padding: 20px;
    color: white;
    text-align: left;
    justify-content: flex-start; 
}

.minister-info img {
    width: 160px; 
    height: auto;
    margin-right: 20px; 
    object-fit: cover;
}

.minister-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
}

.minister-details h3 {
    margin: 5px 0;
    font-size: 1.2rem;
    font-weight: normal;
    color: white;
}

@media only screen and (min-width: 1440px) {
    .container {
        max-width: 1400px;
    }

    .filter-row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; 
        justify-content: center; 
        width: 100%;
        margin-bottom: 20px;
    }

    .filter {
        flex-basis: 30%; 
        flex-grow: 0; 
        flex-shrink: 0; 
        box-sizing: border-box; 
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .navbar {
        padding: 20px 40px;
    }

    .nav-links a {
        font-size: 1rem;
        padding: 15px;
    }
    .filter-row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px; 
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .filter {
        flex-basis: 30%; 
        flex-grow: 0; 
        flex-shrink: 0; 
        box-sizing: border-box; 
    }

    .minister-info img {
    width: 120px;  
    height: auto; 
    object-fit: cover; 
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .navbar {
        padding: 20px 30px;
    }

    .nav-links a {
        font-size: 1rem;
        padding: 15px;
    }

    .filter-container {
        display: none;
    }

    .filter-row {
        flex-direction: column;
        width: 100%;
        gap: 30px; 
        align-items: center;
    }

    .filter {
        width: 100%
    }

    .filter input, .react-select__control {
        width: 100%;
        text-align: center; 
    }

    .floating-filter-menu {
        display: flex;
    }

    .stats-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: 290vh;
    }

    #map, .bar-chart, .minister-info, .pie-chart, .pie-chart-minister-selected, .event-table {
        grid-column: 1 / -1;
        grid-row: auto;
        margin-bottom: 20px;
    }

    #map {
        min-height: 50vh;
    }

    
    .bar-chart{
        min-height: 80vh;
    }

    .pie-chart, .pie-chart-minister-selected{
        min-height: 80vh;;
        overflow: hidden
    }

    .event-table{
        min-height: 80vh;
    }

    .minister-info img {
        width: 300px;  
        height: auto; 
        margin-bottom: 15px;
        object-fit: cover; 
    }
}

@media only screen and (max-width: 767px) {
    .logo-container {
        justify-content: space-between;
        gap: 200px;
    }

    .menu-icon {
        display: block; 
    }

    .navbar {
        flex-direction: column;
        padding: 10px 20px;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px;
        right: 0;
        background-color: #2e2e2e;
        width: 60%;
        padding: 20px 0;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
        z-index: 10000;
    }

    .material-symbols-outlined {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 24px;
        cursor: pointer;
        color: #fff;
        z-index: 10;
      }

    .menu-close-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 24px;
        cursor: pointer;
        color: #fff;
        z-index: 10;
      }

    .nav-links a {
        margin: 10px 0;
        text-align: center;
        padding: 15px 0;
        width: 100%;
    }

    .nav-links.show {
        display: flex; 
    }

    .filter-container {
        display: none;
    }

    .filter-row {
        flex-direction: column;
        width: 100%;
        gap: 30px; 
        align-items: center;
    }

    .filter {
        width: 100%
    }

    .filter input, .react-select__control {
        width: 100%;
        text-align: center; 
    }

    .floating-filter-menu {
        display: flex;
    }

    .stats-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: 290vh;
    }

    #map, .bar-chart, .minister-info, .pie-chart, .pie-chart-minister-selected, .event-table {
        grid-column: 1 / -1;
        grid-row: auto;
        margin-bottom: 20px;
    }

    #map {
        min-height: 50vh;
    }
    
    .bar-chart{
        min-height: 80vh;
    }

    .pie-chart, .pie-chart-minister-selected{
        min-height: 80vh;;
        overflow: hidden
    }

    .event-table{
        min-height: 50vh;
    }

    .minister-details h3 {
        margin: 5px 0;
        font-size: 1rem;
        font-weight: normal;
        color: white;
    }

    .minister-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.5rem;
    }
}


@media only screen and (min-width: 1024px) {
    .floating-filter-menu {
        display: none !important;
    }

    .floating-filter-button {
        display: none;
    }

    .filter-container {
        display: flex;
    }
}


@media only screen and (max-width: 1023px) {
    .minister-info {
        order: -1;
    }

    .bar-chart, .pie-chart, .pie-chart-minister-selected {
        order: 0;
    }
}